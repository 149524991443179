
import { defineComponent, ref } from "vue";
import { apiForgotPassword } from "@/api/auth";

export default defineComponent({
  name: "ForgotPassword",
  components: {},
  setup() {
    const email = ref("");
    const error = ref(false);
    const errorMessage = ref("");
    const buttonText = ref("Send password reset email");
    const buttonDisable = ref(false);
    const send = ref(false);

    const register = async () => {
      error.value = false;
      errorMessage.value = "";
      buttonText.value = "Wait...";
      buttonDisable.value = true;
      const { data } = await apiForgotPassword(email.value);
      if (data.send) {
        send.value = true;
        error.value = false;
      } else {
        error.value = true;
        buttonText.value = "Send password reset email";
        buttonDisable.value = false;
      }
    };

    return {
      email,
      error,
      errorMessage,
      buttonText,
      buttonDisable,
      send,
      register,
    };
  },
});
